/* eslint-disable import/no-unused-modules */
/* eslint-disable react/function-component-definition */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react/jsx-fragments */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { useTranslation } from 'react-i18next';
import styles from '../../pages/styles/devices.module.scss';
import { useOpenContactModal } from '../../hooks';
import { GTM_KEYS, MODAL_SOURCE_BUTTONS } from '../../constants';

const { MODAL: { CONTACT_US_BUTTON } } = GTM_KEYS;
const { SUPPORTED_DEVICES } = MODAL_SOURCE_BUTTONS;

export default function DesktopDevices() {
    const { t } = useTranslation();
    const openContactModal = useOpenContactModal(SUPPORTED_DEVICES);

    return (
        <>
            <div id={styles.mainImageWrapper}>
                <div className={styles.mainImageTextWrapper}>
                    <div className={styles.mainImageLeft}>
                        <div className={styles.mainImageContent}>
                            <h1 className={styles.mainImageTitle}>
                                {t('siteMetadata.supportedDevices.mainH1')}
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
            <section id={styles.whiteSection}>
                <h2 id={styles.whiteSectionTitle}>
                    {t('siteMetadata.supportedDevices.title')}
                </h2>
                <div className={styles.whiteSectionText}>
                    <p>{t('siteMetadata.supportedDevices.description')}</p>
                    <div className={styles.learnMoreBuyButtons}>
                        <div
                            className={styles.cardLearnMoreInline}
                            onClick={openContactModal}
                            data-gtm-id={`${CONTACT_US_BUTTON}-${SUPPORTED_DEVICES}`}
                        >
                            {t('siteMetadata.supportedDevices.askLink')}
                            <FontAwesomeIcon
                                icon={faChevronRight}
                                className={styles.chevronRight}
                            />
                        </div>
                    </div>
                </div>
            </section>
            <section className={styles.deviceCategory}>
                <ul className={styles.deviceCategoryList}>
                    <li>
                        <section className={styles.categorySection}>
                            <h2 className={styles.deviceCategoryTitle}>
                                {t('siteMetadata.supportedDevices.ottSection')}
                            </h2>
                            <h3>
                                {t('siteMetadata.supportedDevices.ottTitle')}
                            </h3>
                            <ul>
                                <li>
                                    {t('siteMetadata.supportedDevices.appleTV')}
                                </li>
                                <li>
                                    {t('siteMetadata.supportedDevices.amazonFire')}
                                </li>
                                <li>
                                    {t('siteMetadata.supportedDevices.google')}
                                </li>
                                <li>
                                    {t('siteMetadata.supportedDevices.roku')}
                                </li>
                                <li>
                                    {t('siteMetadata.supportedDevices.nvidia')}
                                </li>
                                <li>
                                    {t('siteMetadata.supportedDevices.xiaomi')}
                                </li>
                            </ul>
                        </section>
                        <section className={styles.categorySection}>
                            <h3>
                                {t('siteMetadata.supportedDevices.streamingTitle')}
                            </h3>
                            <ul>
                                <li>
                                    {t('siteMetadata.supportedDevices.amazonStick')}
                                </li>
                                <li>
                                    {t('siteMetadata.supportedDevices.rokuStick')}
                                </li>
                                <li>
                                    {t('siteMetadata.supportedDevices.googleStick')}
                                </li>
                            </ul>
                            <p className={styles.categorySectionItal}>{t('siteMetadata.supportedDevices.weAlso')}</p>
                        </section>
                    </li>
                    <li>
                        <section className={styles.categorySection}>
                            <h2 className={styles.deviceCategoryTitle}>
                                {t('siteMetadata.supportedDevices.smartTVsSection')}
                            </h2>
                            <ul>
                                <li dangerouslySetInnerHTML={{__html: t('siteMetadata.supportedDevices.samsung')}} />
                                <li dangerouslySetInnerHTML={{__html: t('siteMetadata.supportedDevices.lg')}} />
                                <li dangerouslySetInnerHTML={{__html: t('siteMetadata.supportedDevices.sony')}} />
                                <li dangerouslySetInnerHTML={{__html: t('siteMetadata.supportedDevices.panasonic')}} />
                                <li dangerouslySetInnerHTML={{__html: t('siteMetadata.supportedDevices.vizio')}} />
                                <li dangerouslySetInnerHTML={{__html: t('siteMetadata.supportedDevices.tcl')}} />
                                <li dangerouslySetInnerHTML={{__html: t('siteMetadata.supportedDevices.hisense')}} />
                                <li dangerouslySetInnerHTML={{__html: t('siteMetadata.supportedDevices.sharp')}} />
                                <li dangerouslySetInnerHTML={{__html: t('siteMetadata.supportedDevices.philips')}} />
                                <li dangerouslySetInnerHTML={{__html: t('siteMetadata.supportedDevices.toshiba')}} />
                            </ul>
                        </section>
                    </li>
                    <li>
                        <section className={styles.categorySectionMargin2}>
                            <ul>
                                <li dangerouslySetInnerHTML={{__html: t('siteMetadata.supportedDevices.insignia')}} />
                                <li dangerouslySetInnerHTML={{__html: t('siteMetadata.supportedDevices.skyworth2')}} />
                                <li dangerouslySetInnerHTML={{__html: t('siteMetadata.supportedDevices.element')}} />
                                <li dangerouslySetInnerHTML={{__html: t('siteMetadata.supportedDevices.xiaomi2')}} />
                                <li dangerouslySetInnerHTML={{__html: t('siteMetadata.supportedDevices.oneplus')}} />
                                <li dangerouslySetInnerHTML={{__html: t('siteMetadata.supportedDevices.comcastxclass')}} />
                                <li dangerouslySetInnerHTML={{__html: t('siteMetadata.supportedDevices.skyglass')}} />
                            </ul>
                        </section>
                    </li>
                    <li>
                        <section className={styles.categorySection}>
                            <h2 className={styles.deviceCategoryTitle}>
                                {t('siteMetadata.supportedDevices.gamingSection')}
                            </h2>
                            <ul>
                                <li dangerouslySetInnerHTML={{__html: t('siteMetadata.supportedDevices.sony2')}} />
                                <li dangerouslySetInnerHTML={{__html: t('siteMetadata.supportedDevices.sony3')}} />
                                <li dangerouslySetInnerHTML={{__html: t('siteMetadata.supportedDevices.microsoft')}} />
                                <li dangerouslySetInnerHTML={{__html: t('siteMetadata.supportedDevices.microsoft2')}} />
                                <li dangerouslySetInnerHTML={{__html: t('siteMetadata.supportedDevices.microsoft3')}} />
                            </ul>
                        </section>
                    </li>
                </ul>
            </section>
            <section className={styles.deviceCategory}>
                <h2 className={styles.deviceCategoryTitle}>
                    {t('siteMetadata.supportedDevices.smartphoneSection')}
                </h2>
                <ul className={styles.deviceCategoryList}>
                    <li>
                        <section className={styles.categorySection}>
                            <h3>
                                {t('siteMetadata.supportedDevices.smartphonesTitle')}
                            </h3>
                            <ul>
                                <li>
                                    {t('siteMetadata.supportedDevices.iPhone14')}
                                </li>
                                <li>
                                    {t('siteMetadata.supportedDevices.iPhone14Max')}
                                </li>
                                <li>
                                    {t('siteMetadata.supportedDevices.iPhone14Pro')}
                                </li>
                                <li>
                                    {t('siteMetadata.supportedDevices.iPhone14ProMax')}
                                </li>
                                <li>
                                    {t('siteMetadata.supportedDevices.iPhone13')}
                                </li>
                                <li>
                                    {t('siteMetadata.supportedDevices.iPhone13mini')}
                                </li>
                                <li>
                                    {t('siteMetadata.supportedDevices.iPhone13Pro')}
                                </li>
                                <li>
                                    {t('siteMetadata.supportedDevices.iPhone13ProMax')}
                                </li>
                                <li>
                                    {t('siteMetadata.supportedDevices.iPhone12')}
                                </li>
                                <li>
                                    {t('siteMetadata.supportedDevices.iPhone12mini')}
                                </li>
                            </ul>
                        </section>
                    </li>
                    <li>
                        <section className={styles.categorySectionMargin}>
                            <ul>
                                <li>
                                    {t('siteMetadata.supportedDevices.iPhone12Pro')}
                                </li>
                                <li>
                                    {t('siteMetadata.supportedDevices.iPhone12ProMax')}
                                </li>
                                <li>
                                    {t('siteMetadata.supportedDevices.iPhoneSE')}
                                </li>
                                <li>
                                    {t('siteMetadata.supportedDevices.iPhoneSE2')}
                                </li>
                                <li>
                                    {t('siteMetadata.supportedDevices.iPhone11')}
                                </li>
                                <li>
                                    {t('siteMetadata.supportedDevices.iPhone11Pro')}
                                </li>
                                <li>
                                    {t('siteMetadata.supportedDevices.iPhone11ProMax')}
                                </li>
                                <li>
                                    {t('siteMetadata.supportedDevices.iPhoneXS')}
                                </li>
                                <li>
                                    {t('siteMetadata.supportedDevices.iPhoneXSMax')}
                                </li>
                                <li>
                                    {t('siteMetadata.supportedDevices.iPhoneXR')}
                                </li>
                            </ul>
                        </section>
                    </li>
                    <li>
                        <section className={styles.categorySectionMargin}>
                            <ul>
                                <li>
                                    {t('siteMetadata.supportedDevices.iPhoneX')}
                                </li>
                                <li>
                                    {t('siteMetadata.supportedDevices.iPhone8')}
                                </li>
                                <li>
                                    {t('siteMetadata.supportedDevices.iPhone8Plus')}
                                </li>
                                <li>
                                    {t('siteMetadata.supportedDevices.GooglePixels')}
                                </li>
                                <li>
                                    {t('siteMetadata.supportedDevices.samsunga')}
                                </li>
                                <li>
                                    {t('siteMetadata.supportedDevices.samsungs')}
                                </li>
                            </ul>
                        </section>
                    </li>
                    <li>
                        <section className={styles.categorySection}>
                            <h3>
                                {t('siteMetadata.supportedDevices.tabletsTitle')}
                            </h3>
                            <ul>
                                <li>
                                    {t('siteMetadata.supportedDevices.ipadPro')}
                                </li>
                                <li>
                                    {t('siteMetadata.supportedDevices.ipadPro2')}
                                </li>
                                <li>
                                    {t('siteMetadata.supportedDevices.ipadAir')}
                                </li>
                                <li>
                                    {t('siteMetadata.supportedDevices.ipad')}
                                </li>
                                <li>
                                    {t('siteMetadata.supportedDevices.ipadMini')}
                                </li>
                                <li>
                                    {t('siteMetadata.supportedDevices.android')}
                                </li>
                            </ul>
                        </section>
                    </li>
                </ul>
            </section>
            <section className={styles.deviceCategory}>
                <h2 className={styles.deviceCategoryTitle}>
                    {t('siteMetadata.supportedDevices.stbSection')}
                </h2>
                <ul className={styles.deviceCategoryList}>
                    <li>
                        <section className={styles.categorySection}>
                            <ul>
                                <li>
                                    {t('siteMetadata.supportedDevices.airtel')}
                                </li>
                                <li>
                                    {t('siteMetadata.supportedDevices.airties')}
                                </li>
                                <li>
                                    {t('siteMetadata.supportedDevices.altech')}
                                </li>
                                <li>
                                    {t('siteMetadata.supportedDevices.amino')}
                                </li>
                                <li>
                                    {t('siteMetadata.supportedDevices.arcadyan')}
                                </li>
                                <li>
                                    {t('siteMetadata.supportedDevices.arris')}
                                </li>
                                <li>
                                    {t('siteMetadata.supportedDevices.broadcom')}
                                </li>
                                <li>
                                    {t('siteMetadata.supportedDevices.cisco')}
                                </li>
                                <li>
                                    {t('siteMetadata.supportedDevices.comcast')}
                                </li>
                                <li>
                                    {t('siteMetadata.supportedDevices.commscope')}
                                </li>
                            </ul>
                        </section>
                    </li>
                    <li>
                        <section className={styles.categorySection}>
                            <ul>
                                <li>
                                    {t('siteMetadata.supportedDevices.digi')}
                                </li>
                                <li>
                                    {t('siteMetadata.supportedDevices.directv')}
                                </li>
                                <li>
                                    {t('siteMetadata.supportedDevices.dishnetwork')}
                                </li>
                                <li>
                                    {t('siteMetadata.supportedDevices.echostar')}
                                </li>
                                <li>
                                    {t('siteMetadata.supportedDevices.entone')}
                                </li>
                                <li>
                                    {t('siteMetadata.supportedDevices.evo')}
                                </li>
                                <li>
                                    {t('siteMetadata.supportedDevices.haier')}
                                </li>
                                <li>
                                    {t('siteMetadata.supportedDevices.huawei')}
                                </li>
                                <li>
                                    {t('siteMetadata.supportedDevices.humax')}
                                </li>
                                <li>
                                    {t('siteMetadata.supportedDevices.hyundai')}
                                </li>
                            </ul>
                        </section>
                    </li>
                    <li>
                        <section className={styles.categorySection}>
                            <ul>
                                <li>
                                    {t('siteMetadata.supportedDevices.kaonmedia')}
                                </li>
                                <li>
                                    {t('siteMetadata.supportedDevices.manhattan')}
                                </li>
                                <li>
                                    {t('siteMetadata.supportedDevices.mediasonic')}
                                </li>
                                <li>
                                    {t('siteMetadata.supportedDevices.moto')}
                                </li>
                                <li>
                                    {t('siteMetadata.supportedDevices.netgem')}
                                </li>
                                <li>
                                    {t('siteMetadata.supportedDevices.nvidia2')}
                                </li>
                                <li>
                                    {t('siteMetadata.supportedDevices.pace')}
                                </li>
                                <li>
                                    {t('siteMetadata.supportedDevices.polsat')}
                                </li>
                                <li>
                                    {t('siteMetadata.supportedDevices.roku2')}
                                </li>
                                <li>
                                    {t('siteMetadata.supportedDevices.sagemcom')}
                                </li>
                            </ul>
                        </section>
                    </li>
                    <li>
                        <section className={styles.categorySection}>
                            <ul>
                                <li>
                                    {t('siteMetadata.supportedDevices.sky')}
                                </li>
                                <li>
                                    {t('siteMetadata.supportedDevices.skyworth')}
                                </li>
                                <li>
                                    {t('siteMetadata.supportedDevices.strong')}
                                </li>
                                <li>
                                    {t('siteMetadata.supportedDevices.tatasky')}
                                </li>
                                <li>
                                    {t('siteMetadata.supportedDevices.technicolor')}
                                </li>
                                <li>
                                    {t('siteMetadata.supportedDevices.tivo')}
                                </li>
                                <li>
                                    {t('siteMetadata.supportedDevices.vantiva')}
                                </li>
                                <li>
                                    {t('siteMetadata.supportedDevices.verizonfios')}
                                </li>
                                <li>
                                    {t('siteMetadata.supportedDevices.zte')}
                                </li>
                            </ul>
                        </section>
                    </li>
                </ul>
            </section>
            <section className={styles.deviceCategory}>
                <h2 className={styles.deviceCategoryTitle}>
                    {t('siteMetadata.supportedDevices.browsersSection')}
                </h2>
                <ul className={styles.deviceCategoryList}>
                    <li>
                        <section className={styles.categorySection}>
                            <ul>
                                <li>
                                    {t('siteMetadata.supportedDevices.chrome')}
                                </li>
                                <li>
                                    {t('siteMetadata.supportedDevices.edge')}
                                </li>
                                <li>
                                    {t('siteMetadata.supportedDevices.firefox')}
                                </li>
                                <li>
                                    {t('siteMetadata.supportedDevices.opera')}
                                </li>
                                <li>
                                    {t('siteMetadata.supportedDevices.any')}
                                </li>
                            </ul>
                        </section>
                    </li>
                </ul>
            </section>
        </>
    );
}

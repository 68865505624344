/* eslint-disable import/no-unused-modules */
/* eslint-disable react/function-component-definition */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react/jsx-fragments */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Layout } from '../../components/common';
import SEO from '../../components/common/SEO';
import ogimage from '../../images/technology/supported-devices/witbe-og-image.jpg';
import { useViewport } from '../../hooks';
import DesktopDevices from '../../components/common/DesktopDevices';
import MobileDevices from '../../components/common/MobileDevices';

const SupportedDevices = () => {
    const { t } = useTranslation();

    const { width } = useViewport();
    const breakpoint = 500;

    return (
        <Layout title='Supported Devices - Witbe'>
            <SEO
                title='Witbe - Supported Devices for Video Testing & Monitoring'
                ogDescription='Discover supported devices for video testing at Witbe. Trust in our automated, proactive testing and monitoring for your devices'
                description='Discover supported devices for video testing at Witbe. Trust in our automated, proactive testing and monitoring for your devices'
                image={ogimage}
            />
            {width < breakpoint ? <MobileDevices /> : <DesktopDevices />}
        </Layout>
    );
};

export default SupportedDevices;
